import React, { useEffect, useState } from 'react';
import style from './UpgradePage.module.css';
import axios from 'axios';
import UpgradeCard from '../../components/UpgradeCard/UpgradeCard';
import { Helmet } from "react-helmet";
import {
	useUser,
} from "@clerk/clerk-react";
import { generateWorkoutRoutine } from '../../utils/generateRoutines';


// let LemonSqueezy = null;

const data = {
	data: {
		type: "checkouts",
		relationships: {
			store: {
				data: {
					type: "stores",
					id: "86022"
				}
			},
			variant: {
				data: {
					type: "variants",
					id: "449803"
				}
			}
		}
	}
};

const data2 = {
	data: {
		type: "checkouts",
		relationships: {
			store: {
				data: {
					type: "stores",
					id: "86022"
				}
			},
			variant: {
				data: {
					type: "variants",
					id: "449804"
				}
			}
		}
	}
};

const data3 = {
	data: {
		type: "checkouts",
		relationships: {
			store: {
				data: {
					type: "stores",
					id: "86022"
				}
			},
			variant: {
				data: {
					type: "variants",
					id: "449802"
				}
			}
		}
	}
};


const UpgradePage = () => {
	const { user } = useUser()


	return (
		<div className={style.UpgradePage}>
			<Helmet>
				<meta charSet="utf-8" />
				<script src="https://app.lemonsqueezy.com/js/lemon.js" defer></script>
				<title>Calcium Plans</title>
			</Helmet>

			{
				user ?
					<>
						<div className={style.UpgradePageText}>
							<span className={style.UpgradePageHeading}>
								Choose your plan
							</span>

							<span className={style.UpgradePageTextContent}>
								<p>
								</p>
							</span>
						</div>


						<div className={style.UpgradePlansWrapper}>
							<UpgradeCard
								planName='Calcium Plus Monthly'
								text='Valid for a month'
								amountText={`$10`}
								planType='calcium-monthly'
								lemonSqueezyData={data}
							/>

							<UpgradeCard
								planName='Calcium Plus Quarterly'
								text='Valid for 3 months'
								amountText={`$25`}
								planType='calcium-quarterly'
								lemonSqueezyData={data2}
							/>

							<UpgradeCard
								planName='Calcium Plus Yearly'
								text='Valid for a year'
								amountText={`$100`}
								planType='calcium-annually'
								lemonSqueezyData={data3}
							/>
						</div>
					</>
					:
					<span className={style.EmptyText}>
						user not logged in
					</span>
			}

		</div>
	)
}

export default UpgradePage;