import React, { useState } from 'react';
import style from './MealPlans.module.css';
import FormInput from '../../components/FormInput/FormInput';

import MealPlan from '../../components/MealPlan/MealPlan';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { generateMeals } from '../../utils/generateMeals';


const MealPlans = (props) => {
	const [mealPlans, setMealPlans] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [userInput, setUserInput] = useState('');



	const prompt = `You are an AI assistant tasked with generating a personalized meal plan based on user input. Your goal is to create a nutritious and varied meal plan that meets the user's specific needs and preferences.
Here is the user's input:
<user_input>
${userInput}
</user_input>
Generate a meal plan for 7 days based on the user's input. Follow these steps:

Analyze the user's input to understand their dietary preferences, restrictions, and goals.
Create a meal plan name that reflects the user's needs (e.g., "High-Protein Vegetarian Plan" or "Low-Carb Weight Loss Plan").
Write a brief reason explaining why this meal plan was generated based on the user's input.
For each day, create three meals: Breakfast, Lunch, and Dinner. Ensure variety and balance across the meal plan.
Use appropriate dish names that align with the user's preferences and nutritional requirements.

this is an example to guide your response structure.

const defaultMealPlans = {
	planName: "Affordable and Easy Nigerian Meal Plan",
	reason: "Based on the user's request for a cheap and easy Nigerian meal plan, this 7-day meal plan focuses on traditional Nigerian dishes that are budget-friendly and simple to prepare.",
	days: [
		{ Breakfast: "Ogi (Pap)", Lunch: "Jollof Rice with Chicken", Dinner: "Egusi Soup with Eba" },
		{ Breakfast: "Akara (Bean Fritters)", Lunch: "Beans and Plantains", Dinner: "Efo Riro with Garri" },
		{ Breakfast: "Moin-Moin", Lunch: "Tuwo Shinkafa with Chicken Stew", Dinner: "Ewedu Soup with Amala" },
		{ Breakfast: "Fried Yam with Eggs", Lunch: "Fufu with Ogbono Soup", Dinner: "Fried Fish with Eba" },
		{ Breakfast: "Oatmeal with Banana and Honey", Lunch: "Fried Rice with Chicken", Dinner: "Vegetable Soup with Garri" },
		{ Breakfast: "Akamu (Pap) with Groundnut", Lunch: "Fried Plantains with Beans", Dinner: "Chicken Stew with Eba" },
		{ Breakfast: "Boiled Yam with Eggs", Lunch: "Semo with Egusi Soup", Dinner: "Jollof Spaghetti" }
	]
};


always present your meal plan in the following JSON format that can be parsed directly:
{
"planName": "Meal plan name",
"reason": "Brief explanation of why this meal plan was generated",
"days":[
"Day 1": {
	"Breakfast": "Dish name",
	"Lunch": "Dish name",
	"Dinner": "Dish name"
	},
	
	"Day 2": {
	"Breakfast": "Dish name",
	"Lunch": "Dish name",
	"Dinner": "Dish name"
	}
	// ... continue for all specified days
]
}
Important notes:

Ensure that the meal plan adheres to any dietary restrictions or preferences mentioned in the user input.
If the user input is unclear or contradictory, always make reasonable assumptions and explain them in the "reason" field.
If the user requests fewer than 7 days, adjust the JSON structure accordingly.
If the user requests more than 7 days, create a 7-day plan and add a note in the "reason" field that the plan can be repeated.

Always Provide the JSON response without any additional explanation or text outside of the JSON structure. Ensure the JSON is valid and can be parsed directly without any escape characters or formatting.`







	const handleMealInput = event => {
		setUserInput(event.target.value);
	}

	const handleGenerateMealPlans = () => {
		console.log('generating routines');
		setIsLoading(true);
		generateMeals(prompt)
			.then((meal) => {
				console.log(meal);
				setMealPlans(JSON.parse(meal));
				setIsLoading(false);
			})
			.catch(err => {
				console.error('Failed to generate meal plan', err);
				setIsLoading(false);
			});
	};

	return (
		<div className={style.MealPlansPage}>
			<h3 className={style.MealPlansPageTitle}>
				Meal plans
			</h3>

			<div className={style.MealPlansInputWrapper}>
				<FormInput
					HeadingText='What type of meal plan do you want?'
					width={window.innerWidth < 500 ? '300px' : '480px'}
					showIsEmpty={true}
					change={handleMealInput}
					placeholder='ex: I want an easy Japanese meal plan with healthy carbs and proteins and fruits.'
				/>
				<PrimaryButton
					text='Generate'
					click={handleGenerateMealPlans}
					width='120px'
				/>
			</div>


			<div className={style.MealPlansWrapper}>
				{isLoading ? (
					<span>Loading...</span>
				) : (
					mealPlans.days && mealPlans.days.length > 0 ? (
						<div>
							<div className={style.MealPlansCards}>
								{
									mealPlans.days.map((day, index) => (
										<MealPlan
											key={index}
											dayName={`Day ${index + 1}`}
											meals={day}
										/>
									))
								}
							</div>
							
							<PrimaryButton
								text='Save'
								click={null}
								width='120px'
							/>
						</div>
					) : (
						<p className={style.EmptyText}>
							Your generated meal plan will appear here.
						</p>
					)
				)}
			</div>
		</div>
	);
};

export default MealPlans;