import React, { useState } from 'react';
import style from './ChallengeCard.module.css';
import arrow from '../../assets/arrowDownIcon.svg';
import WorkoutCard from '../WorkoutCard/WorkoutCard';
import axios from 'axios';


const ChallengeCard = props => {
	const [checked, setChecked] = useState(false)
	const [openWorkouts, setOpenWorkouts] = useState(false)

	const showWorkouts = {
		display: openWorkouts ? 'flex' : 'none',
	};

	return (
		<div className={style.ChallengeCardWrapper}>
			<div className={style.ChallengeCard} onClick={() => setOpenWorkouts(!openWorkouts)}>
				<div className={style.ChallengeDetails}>
					<div className={style.ChallengeInfo}>
						<span className={style.ChallengeName}>{props.workoutName}</span>
						<span className={style.ChallengeNumber}>{props.workoutSets} sets, {props.workoutReps} reps</span>
						{
							props.deleteable && <span
								onClick={props.delete}
								className={style.DeleteButton}>
								Delete
							</span>
						}
					</div>

					<img
						src={arrow}
						alt="" className={style.arrow}
						onClick={() => setOpenWorkouts(!openWorkouts)}
					/>
				</div>
			</div>

			<div style={showWorkouts} className={style.WorkoutCards}>
				<div>
					<a href={`https://www.youtube.com/results?search_query=how+to+${props.workoutName}+exercise`} target='_blank' rel="noreferrer" className={style.DemoLink}>
						Demo
					</a>
				</div>
				{[...Array(+props.workoutSets)].map((index) => (
					<WorkoutCard
						key={index}
						name={props.workoutName}
						reps={props.workoutReps}
						percentagePoints={props.percentagePoints}
					/>
				))}
			</div>
		</div>
	)
}

export default ChallengeCard;